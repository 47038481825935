import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from '../services/storage/storage.service';
import { UtilService } from '../services/util/util.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    public router: Router,
    public storageService: StorageService,
    private util: UtilService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLogin();
  }
  checkLogin() {
    const data = this.util.getUser();
    const url = this.router.url.split('/')[1];
    if (data && data.user.isLoggedIn) {
      switch (url) {
        case '':
          return this.router.navigate(['/new/campaign/new/basic'], {queryParamsHandling: 'merge'}), false;
        case 'auth':
          return this.router.navigate(['/new/campaign/new/basic'], {queryParamsHandling: 'merge'}), false;
        case 'campaign':
          return false;
      }
    } else {
      return true;
    }
  }
}
